<template>
  <div role="'tab-list-' +field.id">
    <b-card-header
      header-tag="header"
      class="p-1"
      role="'role-' +field.id"
      component-type="my-custom-button"
    >
      <b-button
        :style="buttonStyles"
        @click="getSelectedItem(field, obj), activeThisButton()"
        :data.sync="obj"
        block
        href="#"
      >{{field.desc}}</b-button>
    </b-card-header>
  </div>
</template>
<script>
import session from '@/shared/session';

export default {
  props: {
    field: { required: true },
    listFields: {},
    obj: { required: true },
    novoAtendimento: { required: true, type: Boolean, default: false }
  },
  methods: {
    getSelectedItem(obj, data) {
      if (obj.fmt === "RP") {
        data.Motivo = obj.id;
        data.Detalhe = [];
        data.Detalhe[obj.id] = { Tipo: obj.fmt, Motivo: obj.id, Sta: 1, Qtd: 1 };
        if (!data.Superior || session.get('alterasuperior')) {
          data.Superior = obj.id;
        }
      }
      this.$forceUpdate();

    },
    activeThisButton() {
      this.$parent.inactiveAllButtons();
      this.isActive = true;
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    buttonStyles() {
      const changeBg = this.isActive && !this.novoAtendimento;
      return {
        backgroundColor: changeBg ? "#c8ced3" : "#FFFFFF"
      };
    }
  },
  watch: {
    novoAtendimento: function() {
      if (!this.novoAtendimento) {
        this.isActive = false
      }
    }
  },
  created() {
    this.$parent.inactiveAllButtons();
  }
};
</script>