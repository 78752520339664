<template>
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div v-for="(field, index) in schema.vpList" :key="index">
      <component
        :is="field.fmt"
        :field="field"
        :list-fields="schema.vpList"
        :obj.sync="obj"
        :novo-atendimento="novoAtendimento"
        @reload-list="reloadList"
        class="main-content-area"
      ></component>
    </div>
  </div>
</template>
<script>
import session from '@/shared/session';

import NV from './SelecaoNivelTpl';
import SM from './SelecaoMultiplaTpl';
import SU from './SelecaoUnicaTpl';
import RP from './RespostaFinalTpl';

export default {
  methods: {
    reloadList: function(obj) {
      this.$emit("reload-list", obj);
    },
    inactiveAllButtons() {
      var buttons = this.$children.filter(function(child) {
        return child.isActive;
      });
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].isActive = false;
      }
    }
  },
  components: {
    NV: NV,
    SM: SM,
    SU: SU,
    RP: RP
  },
  props: {
    schema: { required: true },
    obj: { required: true },
    novoAtendimento: { required: true, type: Boolean, default: false }
  }
};
</script>