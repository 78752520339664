<template>
  <div role="'tab-list-' +field.id">
    <b-card-header
      header-tag="header"
      class="p-1"
      role="'role-' +field.id"
      @click="getSelectedItem(field, obj)"
      :obj.sync="obj"
    >
      <b-button block href="#">
        {{field.desc}}
        <i class="fa fa-angle-right" style="float: right"></i>
      </b-button>
    </b-card-header>
  </div>
</template>
<script>
import session from '@/shared/session';

export default {
  props: {
    field: { required: true },
    listFields: {},
    obj: { required: true }
  },
  methods: {
    getSelectedItem(obj, data) {

      if (data && data.Detalhe){
        data.Detalhe.forEach((d, index) => {
          if (['RP'].includes(d.Tipo)) {
            delete data.Detalhe[index]
          }
        })
      }

      data.Motivo = data.Motivo > 0 ? data.Motivo : obj.id;
      if (!data.Detalhe || session.get('alterasuperior')) {
        data.Detalhe = [];
      }
      data.Detalhe[obj.id] = { Tipo: obj.fmt, Motivo: obj.id, Sta: 1, Qtd: 0 }
      this.$emit("reload-list", obj, session.get('alterasuperior'));

      session.set('alterasuperior',false);

    }
  },
  created() {
    this.$parent.inactiveAllButtons();
  }
};
</script>