var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12 col-md-12 col-lg-12" },
    _vm._l(_vm.schema.vpList, function(field, index) {
      return _c(
        "div",
        { key: index },
        [
          _c(field.fmt, {
            tag: "component",
            staticClass: "main-content-area",
            attrs: {
              field: field,
              "list-fields": _vm.schema.vpList,
              obj: _vm.obj,
              "novo-atendimento": _vm.novoAtendimento
            },
            on: {
              "update:obj": function($event) {
                _vm.obj = $event
              },
              "reload-list": _vm.reloadList
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }