<template>
  <div role="'tab-list-' +field.id">
      <b-card-header header-tag="header" class="p-1"> 
        <b-btn block href="#" v-b-toggle="'accordion-' + field.id"
          :class="showCollapse ? 'collapsed' : 'collapsed'"
          :aria-expanded="showCollapse ? 'true' : 'true'"
          aria-controls="collapse-4"
          @click="showCollapse = !showCollapse"
        >{{field.desc}}
          <i v-bind:class="{'fa fa-angle-down': !showCollapse, 'fa fa-angle-up': showCollapse}" style="float: right"></i>
        </b-btn>
      </b-card-header>
        <b-collapse class="show" :id="'accordion-'+field.id" visible collapsed accordion="'my-accordion-' +field.id" role="'role-' +field.id">
          <b-card-body>
            <b-form-group
            :label-cols="1"
            class="checkAtendimento"
            >
            <b-form-checkbox-group 
              :value-field="'id'"
              :text-field="'desc'"
              :options= field.selecao
              @change="getSelectedItem"
              >
            </b-form-checkbox-group>
          </b-form-group>
          </b-card-body>
        </b-collapse>
    </div>
</template>
<script>
import session from '@/shared/session';

export default {
  props: {
    field: { required: true },
    listFields: {},
    obj: { required: true }
  },
  methods: {
    getSelectedItem(checked) {

      this.obj.Motivo = this.obj.Motivo > 0 ? this.obj.Motivo : this.field.id;
      if (!this.obj.Detalhe) {
        this.obj.Detalhe = [];
      }
      if (checked.length === 0) {
        delete this.obj.Detalhe[this.field.id]
        this.field.selecao.forEach(s => {
          if (this.obj.Detalhe && this.obj.Detalhe[s.id]) {
            delete this.obj.Detalhe[s.id]
          }
        })
      } else {
        const itens = this.field.selecao.map(s => s.id)
        const chks = checked.map(v => v)
        const rm = _.difference(itens, chks)
        if (rm.length > 0) {
          rm.forEach(r => {
            delete this.obj.Detalhe[r]
          })
        }
        this.obj.Detalhe[this.field.id] = { Tipo: this.field.fmt, Motivo: this.field.id, Sta: 1, Qtd: 0 }
        checked.forEach(v => {
          this.obj.Detalhe[v] = { Detalhe: this.field.id, Motivo: v, Sta: 1, Qtd: 1 }
        });
      }

    }
  },
  data() {
    return {
      showCollapse: true
    };
  },
  created() {
    session.set('alterasuperior',false);
    this.$parent.inactiveAllButtons();
    this.field.selecao.forEach(s => {
      if (this.obj.Detalhe && this.obj.Detalhe[s.id]) {
        delete this.obj.Detalhe[s.id]
      }
    })
  }
};
</script>