var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { role: "'tab-list-' +field.id" } },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            role: "'role-' +field.id",
            obj: _vm.obj
          },
          on: {
            click: function($event) {
              return _vm.getSelectedItem(_vm.field, _vm.obj)
            },
            "update:obj": function($event) {
              _vm.obj = $event
            }
          }
        },
        [
          _c("b-button", { attrs: { block: "", href: "#" } }, [
            _vm._v("\n      " + _vm._s(_vm.field.desc) + "\n      "),
            _c("i", {
              staticClass: "fa fa-angle-right",
              staticStyle: { float: "right" }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }