var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { role: "'tab-list-' +field.id" } },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            role: "'role-' +field.id",
            "component-type": "my-custom-button"
          }
        },
        [
          _c(
            "b-button",
            {
              style: _vm.buttonStyles,
              attrs: { data: _vm.obj, block: "", href: "#" },
              on: {
                click: function($event) {
                  _vm.getSelectedItem(_vm.field, _vm.obj),
                    _vm.activeThisButton()
                },
                "update:data": function($event) {
                  _vm.obj = $event
                }
              }
            },
            [_vm._v(_vm._s(_vm.field.desc))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }