<template>
  <div role="'tab-list-' +field.id">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-btn
        block
        href="#"
        v-b-toggle="'accordion-' + field.id"
        :class="showCollapse ? 'collapsed' : null"
        :aria-expanded="showCollapse ? 'true' : 'false'"
        aria-controls="collapse-4"
        @click="showCollapse = !showCollapse"
      >
        {{field.desc}}
        <i :class="{'fa fa-angle-down': !showCollapse,  'fa fa-angle-up': showCollapse}" style="float: right"></i>
      </b-btn>
    </b-card-header>
    <b-collapse
      :id="'accordion-'+field.id"
      visible
      collapsed
      accordion="'my-accordion-' +field.id"
      role="'role-' +field.id"
    >
      <b-card-body>
        <b-form-group label-for="basicInlineRadios" :label-cols="1" class="checkAtendimento">
          <b-form-radio-group
            id="basicInlineRadios"
            :plain="true"
            :value-field="'id'"
            :text-field="'desc'"
            :options="field.selecao"
            @change="getSelectedItem"
            :checked="1"
          ></b-form-radio-group>
        </b-form-group>
      </b-card-body>
    </b-collapse>
  </div>
</template>
<script>
import session from '@/shared/session';

export default {
  props: {
    field: { required: true },
    listFields: {},
    obj: { required: true }
  },
  methods: {
    getSelectedItem(checked) {

      this.obj.Motivo = this.obj.Motivo > 0 ? this.obj.Motivo : this.field.id;
      if (!this.obj.Detalhe) {
        this.obj.Detalhe = [];
      }
      this.field.selecao.forEach(s => {
        if (this.obj.Detalhe && this.obj.Detalhe[s.id]) {
          delete this.obj.Detalhe[s.id]
        }
      })
      this.obj.Detalhe[this.field.id] = { Tipo: this.field.fmt, Motivo: this.field.id, Sta: 1, Qtd: 0 };
      this.obj.Detalhe[checked] = { Detalhe: this.field.id, Motivo: checked, Sta: 1, Qtd: 1 };

    },
    show: function() {
      return true;
    }
  },
  data() {
    return {
      showCollapse: true
    };
  },
  created() {
    session.set('alterasuperior',false);
    this.$parent.inactiveAllButtons();
    this.field.selecao.forEach(s => {
      if (this.obj.Detalhe && this.obj.Detalhe[s.id]) {
        delete this.obj.Detalhe[s.id]
      }
    })
  }
};
</script>