var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { role: "'tab-list-' +field.id" } },
    [
      _c(
        "b-card-header",
        { staticClass: "p-1", attrs: { "header-tag": "header", role: "tab" } },
        [
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: "accordion-" + _vm.field.id,
                  expression: "'accordion-' + field.id"
                }
              ],
              class: _vm.showCollapse ? "collapsed" : null,
              attrs: {
                block: "",
                href: "#",
                "aria-expanded": _vm.showCollapse ? "true" : "false",
                "aria-controls": "collapse-4"
              },
              on: {
                click: function($event) {
                  _vm.showCollapse = !_vm.showCollapse
                }
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.field.desc) + "\n      "),
              _c("i", {
                class: {
                  "fa fa-angle-down": !_vm.showCollapse,
                  "fa fa-angle-up": _vm.showCollapse
                },
                staticStyle: { float: "right" }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            id: "accordion-" + _vm.field.id,
            visible: "",
            collapsed: "",
            accordion: "'my-accordion-' +field.id",
            role: "'role-' +field.id"
          }
        },
        [
          _c(
            "b-card-body",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "checkAtendimento",
                  attrs: { "label-for": "basicInlineRadios", "label-cols": 1 }
                },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "basicInlineRadios",
                      plain: true,
                      "value-field": "id",
                      "text-field": "desc",
                      options: _vm.field.selecao,
                      checked: 1
                    },
                    on: { change: _vm.getSelectedItem }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }