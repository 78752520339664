var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "animated fadeIn", attrs: { id: "app" } }, [
    _c(
      "div",
      { staticClass: "content pb-0" },
      [
        _c(
          "b-modal",
          {
            ref: "modal",
            attrs: {
              id: "modal-prevent-closing",
              size: "lg",
              scrollable: "",
              centered: "",
              "no-close-on-esc": "",
              title: _vm.formSchema.titleDesc
            }
          },
          [
            _c("v-form", {
              attrs: {
                schema: _vm.formSchema,
                obj: _vm.filaData,
                "novo-atendimento": _vm.novoAtendimento
              },
              on: { "reload-list": _vm.reloadList }
            }),
            _c("template", { slot: "modal-footer" }, [
              _c(
                "div",
                { staticClass: "footer-center" },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: {
                        variant: "danger",
                        disabled: !_vm.habilitaVoltar
                      },
                      on: {
                        click: function($event) {
                          return _vm.voltar()
                        }
                      }
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c(
                    "b-btn",
                    {
                      on: {
                        click: function($event) {
                          return _vm.saveMotivo()
                        }
                      }
                    },
                    [_vm._v("Finalizar")]
                  ),
                  _c(
                    "b-btn",
                    {
                      on: {
                        click: function($event) {
                          return _vm.newAtendimento()
                        }
                      }
                    },
                    [_vm._v("Finalizar e Novo")]
                  )
                ],
                1
              )
            ])
          ],
          2
        ),
        _c(
          "b-modal",
          {
            ref: "modal-perf",
            attrs: {
              id: "modal-performance",
              size: "md",
              "body-class": "row",
              scrollable: "",
              centered: "",
              "no-close-on-esc": "",
              title: "Ver performance de: " + _vm.nome_user_perf
            }
          },
          [
            !_vm.isUserPerformance
              ? _c(
                  "div",
                  _vm._l(_vm.usersPerformance, function(user, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "col-md-6 col-sm-6 select-user-perf"
                      },
                      [
                        _c(
                          "label",
                          {
                            on: {
                              click: function($event) {
                                return _vm.selectedUserPerfor(user)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.getNomeFuncionario(user)))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isUserPerformance
              ? _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _c(
                      "b-form",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { width: "350px" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.loginPerformance()
                          }
                        }
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mb-4 input-opacity" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "icon-lock" })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "password",
                                placeholder: "Senha",
                                autocomplete: "current-password"
                              },
                              on: {
                                focus: function($event) {
                                  _vm.isTyping = true
                                },
                                blur: function($event) {
                                  _vm.isTyping = false
                                }
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("template", { slot: "modal-footer" }, [
              _c(
                "div",
                { staticClass: "footer-center" },
                [
                  _vm.isUserPerformance
                    ? _c(
                        "b-btn",
                        {
                          on: {
                            click: function($event) {
                              return _vm.loginPerformance()
                            }
                          }
                        },
                        [_vm._v("Confirma")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ],
          2
        ),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              {
                staticStyle: { bottom: "10px" },
                attrs: { sm: "6", lg: "6", md: "6" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "font-weight-bold",
                    staticStyle: { "font-size": "16px", color: "#f68b29" }
                  },
                  [_vm._v(_vm._s(_vm.infor_loja))]
                )
              ]
            ),
            _c(
              "b-col",
              {
                staticStyle: { bottom: "10px" },
                attrs: { sm: "6", lg: "6", md: "6" }
              },
              [
                _c(
                  "b-btn",
                  {
                    staticStyle: { float: "right" },
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.openPerformance()
                      }
                    }
                  },
                  [_vm._v("Perfomance")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { sm: "6", lg: "6", md: "6" } },
              [
                _c(
                  "b-card-group",
                  { attrs: { deck: "" } },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          "border-variant": "primary",
                          header: "Fila de Atendimento",
                          "header-bg-variant": "primary",
                          "header-text-variant": "white",
                          align: "center"
                        }
                      },
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "items",
                            attrs: {
                              list: _vm.orderedUsersFilaAtend,
                              "ghost-class": "ghost",
                              sort: false,
                              group: "people"
                            },
                            on: { change: _vm.changeFilaAtend }
                          },
                          _vm._l(_vm.orderedUsersFilaAtend, function(element) {
                            return _c(
                              "div",
                              {
                                key: "element-" + element.lista_id,
                                staticClass: "item"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "user-detail right container mt-1"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-detail__avatar-circle"
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "img-responsive img-circle img-avatar",
                                          attrs: {
                                            src: _vm.getPhoto(element.foto)
                                          },
                                          on: { error: _vm.getAvatar }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-left d-flex align-items-center",
                                        staticStyle: {
                                          padding: "5px 10px",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getNomeFuncionario(element)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "pt-2 border-top" }, [
                          _c(
                            "div",
                            {
                              staticClass: "w-50 float-left",
                              class: {
                                "cursor-pointer": _vm.scrolls.enableScrollFADown
                              },
                              on: {
                                click: function($event) {
                                  return _vm.scrollBottom("FA")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-chevron-down fa-2x",
                                class: {
                                  "text-primary":
                                    _vm.scrolls.enableScrollFADown,
                                  "text-secondary": !_vm.scrolls
                                    .enableScrollFADown
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "w-50 float-left",
                              class: {
                                "cursor-pointer": _vm.scrolls.enableScrollFAUp
                              },
                              on: {
                                click: function($event) {
                                  return _vm.scrollTop("FA")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-chevron-up fa-2x",
                                class: {
                                  "text-primary": _vm.scrolls.enableScrollFAUp,
                                  "text-secondary": !_vm.scrolls
                                    .enableScrollFAUp
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { sm: "6", lg: "6", md: "6" } },
              [
                _c(
                  "b-card-group",
                  { attrs: { deck: "" } },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          "border-variant": "primary",
                          header: "Em Atendimento",
                          "header-bg-variant": "primary",
                          "header-text-variant": "white",
                          align: "center"
                        }
                      },
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "items",
                            attrs: {
                              list: _vm.orderedUsersAtend,
                              "ghost-class": "ghost",
                              handle: ".dragger",
                              sort: false,
                              group: "people",
                              move: _vm.checkMoveAtend
                            },
                            on: { change: _vm.changeAtend }
                          },
                          _vm._l(_vm.orderedUsersAtend, function(element) {
                            return _c(
                              "div",
                              {
                                key: "element-" + element.lista_id,
                                staticClass: "item",
                                on: {
                                  click: function($event) {
                                    return _vm.showModal(element)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "user-detail right container mt-1"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-detail__avatar-circle"
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "img-responsive img-circle img-avatar",
                                          attrs: {
                                            src: _vm.getPhoto(element.foto)
                                          },
                                          on: { error: _vm.getAvatar }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-left d-flex align-items-center",
                                        staticStyle: {
                                          padding: "5px 10px",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getNomeFuncionario(element)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "pt-2 border-top" }, [
                          _c(
                            "div",
                            {
                              staticClass: "w-50 float-left",
                              class: {
                                "cursor-pointer": _vm.scrolls.enableScrollEADown
                              },
                              on: {
                                click: function($event) {
                                  return _vm.scrollBottom("EA")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-chevron-down fa-2x",
                                class: {
                                  "text-primary":
                                    _vm.scrolls.enableScrollEADown,
                                  "text-secondary": !_vm.scrolls
                                    .enableScrollEADown
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "w-50 float-left",
                              class: {
                                "cursor-pointer": _vm.scrolls.enableScrollEAUp
                              },
                              on: {
                                click: function($event) {
                                  return _vm.scrollTop("EA")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-chevron-up fa-2x",
                                class: {
                                  "text-primary": _vm.scrolls.enableScrollEAUp,
                                  "text-secondary": !_vm.scrolls
                                    .enableScrollEAUp
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("footer", { staticClass: "footer pb-0 pt-3" }, [
      _c(
        "div",
        { attrs: { role: "tablist" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                {
                  staticClass: "p-1",
                  attrs: { "header-tag": "header", role: "tab" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        href: "#",
                        variant: "primary",
                        "text-variant": "white"
                      },
                      on: { click: _vm.toggleAccordion }
                    },
                    [_vm._v("Vendedores Offline")]
                  )
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  staticClass: "ml-5 mr-5",
                  staticStyle: { height: "10em", overflow: "hidden hidden" },
                  attrs: {
                    id: "accordion-footer",
                    visible: "",
                    accordion: "accordion-footer",
                    role: "tabpanel"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "scroll-right float-left p-2",
                      class: {
                        "cursor-pointer": _vm.scrolls.enableScrollRight
                      },
                      on: { click: _vm.scrollRight }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-chevron-left fa-2x mt-5",
                        class: {
                          "text-primary": _vm.scrolls.enableScrollRight,
                          "text-secondary": !_vm.scrolls.enableScrollRight
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "scroll-left float-right p-2",
                      class: { "cursor-pointer": _vm.scrolls.enableScrollLeft },
                      on: { click: _vm.scrollLeft }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-chevron-right fa-2x mt-5",
                        class: {
                          "text-primary": _vm.scrolls.enableScrollLeft,
                          "text-secondary": !_vm.scrolls.enableScrollLeft
                        }
                      })
                    ]
                  ),
                  _c(
                    "b-card-body",
                    { staticClass: "animate", attrs: { id: "offline-list" } },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "offline-items d-flex",
                          attrs: {
                            list: _vm.orderedUsersOffline,
                            group: "people",
                            "ghost-class": "ghost",
                            sort: false
                          },
                          on: { change: _vm.changeOff }
                        },
                        _vm._l(_vm.orderedUsersOffline, function(element) {
                          return _c(
                            "div",
                            {
                              key: "element-" + element.lista_id,
                              staticClass: "item mt-2 text-center",
                              staticStyle: { width: "120px" }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "img-responsive img-circle img-avatar",
                                attrs: { src: _vm.getPhoto(element.foto) },
                                on: { error: _vm.getAvatar }
                              }),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.getNomeFuncionario(element)))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }