<template>
  <div id="app" class="animated fadeIn">
    <div class="content pb-0">
      <b-modal
        id="modal-prevent-closing"
        size="lg"
        ref="modal"
        scrollable
        centered
        no-close-on-esc
        :title="formSchema.titleDesc"
      >
        <v-form :schema="formSchema" :obj="filaData" @reload-list="reloadList" :novo-atendimento="novoAtendimento"></v-form>
        <template slot="modal-footer">
          <div class="footer-center">
            <b-btn @click="voltar()" variant="danger" :disabled="!habilitaVoltar">Voltar</b-btn>
            <b-btn @click="saveMotivo()">Finalizar</b-btn>
            <b-btn @click="newAtendimento()">Finalizar e Novo</b-btn>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="modal-performance"
        size="md"
        ref="modal-perf"
        body-class="row"
        scrollable
        centered
        no-close-on-esc
        :title="`Ver performance de: ` + nome_user_perf"
      >
        <div v-if="!isUserPerformance">
          <div class="col-md-6 col-sm-6 select-user-perf" v-for="(user, index) in usersPerformance" :key="index">
            <label @click="selectedUserPerfor(user)">{{ getNomeFuncionario(user) }}</label>
          </div>
        </div>
        <div v-if="isUserPerformance" class="mx-auto">
          <b-form @submit.prevent="loginPerformance()" class="col-md-12" style="width: 350px">
            <b-input-group class="mb-4 input-opacity">
              <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
              <b-form-input type="password" class="form-control" placeholder="Senha" v-model="password" autocomplete="current-password" @focus="isTyping = true" @blur="isTyping = false" />
            </b-input-group>
          </b-form>
        </div>  
        <template slot="modal-footer">
          <div class="footer-center">
            <b-btn @click="loginPerformance()" v-if="isUserPerformance">Confirma</b-btn>
          </div>
        </template>
      </b-modal>
      <b-row>
        <b-col sm="6" lg="6" md="6" style="bottom: 10px;">
          <label class="font-weight-bold" style="font-size: 16px;color: #f68b29;">{{infor_loja}}</label>
        </b-col>
        <b-col sm="6" lg="6" md="6" style="bottom:10px">
          <b-btn variant="primary" @click="openPerformance()" style="float:right">Perfomance</b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" lg="6" md="6">
          <b-card-group deck>
            <b-card
              border-variant="primary"
              header="Fila de Atendimento"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              class="mr-1"
            >
              <draggable
                class="items"
                :list="orderedUsersFilaAtend"
                ghost-class="ghost"
                :sort="false"
                group="people"
                @change="changeFilaAtend"
              >
                <div
                  class="item"
                  v-for="(element) in orderedUsersFilaAtend"
                  :key="`element-${element.lista_id}`"
                >
                  <div class="user-detail right container mt-1">
                    <div class="user-detail__avatar-circle">
                      <!-- <div
                        class="ratio img-responsive img-circle"
                        :style="`background-image: url(${getPhoto(element.foto)})`"
                      ></div> -->
                      <img
                        :src="getPhoto(element.foto)"
                        class="img-responsive img-circle img-avatar"
                        @error="getAvatar" />
                    </div>
                    <div
                      class="font-weight-bold text-left d-flex align-items-center"
                      style="padding: 5px 10px;font-size: 14px"
                    >{{getNomeFuncionario(element)}}</div>
                  </div>
                </div>
              </draggable>
              <div class="pt-2 border-top">
                <div class="w-50 float-left" :class="{'cursor-pointer': scrolls.enableScrollFADown}" @click="scrollBottom('FA')">
                  <i class="fa fa-chevron-down fa-2x" :class="{'text-primary': scrolls.enableScrollFADown, 'text-secondary': !scrolls.enableScrollFADown}"></i>
                </div>
                <div class="w-50 float-left" :class="{'cursor-pointer': scrolls.enableScrollFAUp}" @click="scrollTop('FA')">
                  <i class="fa fa-chevron-up fa-2x" :class="{'text-primary': scrolls.enableScrollFAUp, 'text-secondary': !scrolls.enableScrollFAUp}"></i>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-col>

        <b-col sm="6" lg="6" md="6">
          <b-card-group deck>
            <b-card
              border-variant="primary"
              header="Em Atendimento"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              class="ml-1"
            >
              <draggable
                class="items"
                :list="orderedUsersAtend"
                ghost-class="ghost"
                handle=".dragger"
                :sort="false"
                group="people"
                @change="changeAtend"
                :move="checkMoveAtend"
              >
                <div
                  class="item"
                  v-for="(element) in orderedUsersAtend"
                  :key="`element-${element.lista_id}`"
                  v-on:click="showModal(element)"
                >
                  <div class="user-detail right container mt-1">
                    <div class="user-detail__avatar-circle">
                      <!-- <div
                        class="ratio img-responsive img-circle"
                        :style="`background-image: url(${getPhoto(element.foto)})`"
                      ></div> -->
                      <img
                        :src="getPhoto(element.foto)"
                        class="img-responsive img-circle img-avatar"
                        @error="getAvatar" />
                    </div>
                    <div
                      class="font-weight-bold text-left d-flex align-items-center"
                      style="padding: 5px 10px; font-size: 14px"
                    >{{getNomeFuncionario(element)}}</div>
                  </div>
                </div>
              </draggable>
              <div class="pt-2 border-top">
                <div class="w-50 float-left" :class="{'cursor-pointer': scrolls.enableScrollEADown}" @click="scrollBottom('EA')">
                  <i class="fa fa-chevron-down fa-2x" :class="{'text-primary': scrolls.enableScrollEADown, 'text-secondary': !scrolls.enableScrollEADown}"></i>
                </div>
                <div class="w-50 float-left" :class="{'cursor-pointer': scrolls.enableScrollEAUp}" @click="scrollTop('EA')">
                  <i class="fa fa-chevron-up fa-2x" :class="{'text-primary': scrolls.enableScrollEAUp, 'text-secondary': !scrolls.enableScrollEAUp}"></i>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>

    <footer class="footer pb-0 pt-3">
      <div role="tablist">
        <b-card no-body class="mb-0">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              @click="toggleAccordion"
              variant="primary"
              text-variant="white"
            >Vendedores Offline</b-button>
          </b-card-header>
          <b-collapse id="accordion-footer" visible accordion="accordion-footer" role="tabpanel" class="ml-5 mr-5" style="height: 10em; overflow: hidden hidden;">
            <div class="scroll-right float-left p-2" :class="{'cursor-pointer': scrolls.enableScrollRight}" @click="scrollRight">
              <i class="fa fa-chevron-left fa-2x mt-5" :class="{'text-primary': scrolls.enableScrollRight, 'text-secondary': !scrolls.enableScrollRight}"></i>
            </div>
            <div class="scroll-left float-right p-2" :class="{'cursor-pointer': scrolls.enableScrollLeft}" @click="scrollLeft">
              <i class="fa fa-chevron-right fa-2x mt-5" :class="{'text-primary': scrolls.enableScrollLeft, 'text-secondary': !scrolls.enableScrollLeft}"></i>
            </div>

            <b-card-body id="offline-list" class="animate">
              <draggable
                :list="orderedUsersOffline"
                group="people"
                @change="changeOff"
                ghost-class="ghost"
                :sort="false"
                class="offline-items d-flex"
              >
                <div
                  class="item mt-2 text-center"
                  style="width: 120px;"
                  v-for="(element) in orderedUsersOffline"
                  :key="`element-${element.lista_id}`"
                >
                  <!-- <div class="ratio img-responsive img-circle" :style="`background-image: url(${getPhoto(element.foto)})`"></div> -->
                  <img :src="getPhoto(element.foto)" class="img-responsive img-circle img-avatar" @error="getAvatar" />
                  <span class="font-weight-bold">{{getNomeFuncionario(element)}}</span>
                </div>
              </draggable>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </footer>
  </div>
</template>
<script>

import draggable from "vuedraggable";
import API from "@/core/api";
import io from "socket.io-client";
import session from "@/shared/session";
import easyScroll from 'easy-scroll';
import sha256 from 'sha256';
import vForm from './MotivoFormTpl';

const motivoAPI = new API("api/motivo");
const initFuncAPI = new API("api/init_funcionarios");
const loadFuncAPI = new API("api/load_funcionarios");
const insertListaVezAPI = new API("api/insert_listavez");
const inativaListaVezAPI = new API("api/inativa_listavez");
const initMotivosAPI = new API("api/init_motivos");
const authPerfAPI = new API('api/auth-performance');

export default {
  name: "FilaAtendimento",
  display: "Lista da vez",
  order: 1,
  components: {
    draggable,
    vForm
  },
  destroyed() {
    this.socket.close()
  },
  data() {
    return {
      selected: "",
      usersOffline: [],
      usersFilaAtend: [],
      usersAtend: [],
      usersPerformance: [],
      formSchema: {},
      filaData: {
        Sta: 1
      },
      socket: io(process.env.VUE_APP_SOCKET_URL, {
        query: { room: (session.get('loja')) },
        path: process.env.NODE_ENV === 'development' ? '/socket.io' : '/backend/socket.io'
      }),
      infor_loja: '',
      nome_user_perf: '',
      login: '',
      password: '',
      habilitaVoltar: false,
      novoAtendimento: false,
      isUserPerformance: false,
      scrolls: {
        enableScrollRight: false,
        enableScrollLeft: false,

        enableScrollFADown: false,
        enableScrollFAUp: false,

        enableScrollEADown: false,
        enableScrollEAUp: false
      }
    };
  },
  computed: {
    orderedUsersFilaAtend: function() {
      return _.orderBy(this.usersFilaAtend, "lista_id");
    },
    orderedUsersAtend: function() {
      return _.orderBy(this.usersAtend, "lista_id");
    },
    orderedUsersOffline: function() {
      return _.orderBy(this.usersOffline, "lista_id");
    }
  },
  methods: {
    reloadListaVez: async function() {
      try {
        const { data } = await loadFuncAPI.get({loja: (session.get("loja"))});
        this.usersPerformance = data;
        this.usersOffline = data.filter(({ situacao }) => situacao === 0);
        this.usersFilaAtend = data.filter(({ situacao }) => situacao === 1);
        this.usersAtend = data.filter(({ situacao }) => situacao === 2);
        this.ajustOfflines()
        this.ajustArrows()
      } catch (e) {
        console.error(e);
      }
    },
    ajustOfflines() {
      const el = document.getElementById('offline-list')
      let base = ((this.usersOffline.length / 4) + .5) * 100
      if (base < 105) {
        base = 105
      }
      el.style.width = `${base}%`
    },
    ajustArrows() {
      const users = {
        offline: this.usersOffline.length,
        fila: this.usersFilaAtend.length,
        atend: this.usersAtend.length
      }
      const el = {
        offline: document.getElementById('offline-list'),
        fila: document.getElementsByClassName('items')[0],
        atend: document.getElementsByClassName('items')[1]
      }
      const marginOfflines = Number(el.offline.style.marginLeft.replace(/[^\d-]/g, ''))

      if (marginOfflines < -20) {
        this.scrolls.enableScrollRight = true
      } else {
        this.scrolls.enableScrollRight = false
      }

      if (marginOfflines > (-1 * (users.offline - 3) * 123)) {
        this.scrolls.enableScrollLeft = true
      } else {
        this.scrolls.enableScrollLeft = false
      }

      // Fila de Atendimento
      if (users.fila <= 7) {
        this.scrolls.enableScrollFADown = false
        this.scrolls.enableScrollFAUp = false
      } else {
        this.scrolls.enableScrollFADown = true
      }

      if (el.fila.scrollTop === 0) {
        this.scrolls.enableScrollFAUp = false
      } else {
        this.scrolls.enableScrollFAUp = true
      }

      // Em Atendimento
      if (users.atend <= 7) {
        this.scrolls.enableScrollEADown = false
        this.scrolls.enableScrollEAUp = false
      } else {
        this.scrolls.enableScrollEADown = true
      }

      if (el.atend.scrollTop === 0) {
        this.scrolls.enableScrollEAUp = false
      } else {
        this.scrolls.enableScrollEAUp = true
      }
    },
    initFuncionarios: async function() {
      try {
        await initFuncAPI.get({loja: (session.get("loja"))});
        this.reloadListaVez();
      } catch (e) {
        console.error(e);
      }
    },
    showModal: async function(param) {
      try {
        session.set('alterasuperior', true);
        const { data } = await initMotivosAPI.get();

        this.$refs["modal"].show();
        this.filaData = {
          ComListaVez: param.lista_id,
          Sta: 1
        };
        this.formSchema = {
          vpListOld: [],
          vpList: data.selecao,
          titleDesc: "Atendimento de " + this.getNomeFuncionario(param) + ":",
          objAdded: param
        };
        this.habilitaVoltar = false;
        this.novoAtendimento = false;
      } catch (e) {
        console.error(e);
      }
    },
    showModalPerf: async function() {
      try {
        this.$refs["modal-perf"].show();
        this.password = '';
        this.isUserPerformance = false;
      } catch (e) {
        console.error(e);
      }
    },
    reloadList: function(params) {

      if (params.fmt && params.fmt === 'NV') {
        if (!this.filaData.Superior || session.get('alterasuperior')) {
          this.filaData.Superior = params.id
        }
        params = params.selecao
      } else {
        this.filaData.Superior = null
      }

      this.formSchema.vpListOld.push([ ...this.formSchema.vpList ]);
      this.formSchema.vpList = params;

      const { vpListOld } = this.formSchema
      this.habilitaVoltar = vpListOld.length > 0

      this.filaData.Motivo = null
    },
    voltar() {
      const { vpListOld } = this.formSchema
      if (vpListOld.length > 0) {
        this.formSchema.vpList = vpListOld[vpListOld.length-1]
        this.formSchema.vpListOld = vpListOld.slice(0, -1)
      }
      this.habilitaVoltar = this.formSchema.vpListOld.length > 0

      if (!this.habilitaVoltar) {
        this.filaData.Superior = null;
        this.filaData.Detalhe = [];
        this.filaData.Motivo = null;

        session.set('alterasuperior',true);

      } else {
        const SMandSU = this.filaData.Detalhe
          .filter(({Tipo, Detalhe}) => ['SM', 'SU'].includes(Tipo) || Detalhe)

        if (SMandSU.length === 0) {
          const lastIndex = Object.keys(this.filaData.Detalhe).pop()
          this.filaData.Detalhe.splice(lastIndex, 1)
        } else {
          const list = this.formSchema.vpList.map(v => v.id)
          const nv = this.filaData.Detalhe
            .filter(({Tipo, Detalhe, Motivo}) => ['NV'].includes(Tipo) && list.includes(Motivo))

          const excludes = SMandSU.concat(nv)

          if (excludes.length > 0) {
            this.filaData.Detalhe.forEach((d, index) => {
              const find = excludes.find(d => d.Motivo === index)
              if (find) {
                delete this.filaData.Detalhe[index]
              }
            })
          }
        }
      }

    },
    saveMotivo: async function() {
      if (!this.filaData.Motivo) {
        return swal({
          title: "Informação",
          text: "Informe um motivo.",
          icon: "info",
          dangerMode: true
        });
      }

      this.$refs["modal"].hide();
      this.filaData.Detalhe = this.filaData.Detalhe.filter(d => d && d.Motivo)
      await motivoAPI.post(this.filaData);
      await insertListaVezAPI.post({
        ...this.formSchema.objAdded,
        situacao: 1
      });
      await inativaListaVezAPI.post({ ...this.formSchema.objAdded });
      this.socket.emit("SEND_RELOAD_FILA_DAVEZ");

      session.set('alterasuperior',true);
    },
    newAtendimento: async function() {
      if (!this.filaData.Motivo) {
        return swal({
          title: "Informação",
          text: "Informe um motivo.",
          icon: "info",
          dangerMode: true
        });
      }
      this.novoAtendimento = true

      this.filaData.Detalhe = this.filaData.Detalhe.filter(d => d && d.Motivo)
      await motivoAPI.post(this.filaData);
      let listaId = await insertListaVezAPI.post({
        ...this.formSchema.objAdded,
        situacao: 2
      }).then(data => { return data.data.id });

      await inativaListaVezAPI.post({ ...this.formSchema.objAdded });

      const { data } = await initMotivosAPI.get();
      const param = { ...this.formSchema.objAdded, lista_id: listaId};

      this.filaData = { ComListaVez: param.lista_id, Sta: 1 };
      this.formSchema = {
        vpListOld: [],
        vpList: data.selecao,
        titleDesc: "Atendimento de " + this.getNomeFuncionario(param) + ":",
        objAdded: param
      };
      this.habilitaVoltar = false;
      this.novoAtendimento = false;

      session.set('alterasuperior',true);

    },
    getNomeFuncionario(e) {
      if (!e.colaborador_desc || e.colaborador_desc === '') {
        return e.colaborador_desc_nome.split(' ')[0]
      }
      return e.colaborador_desc.split(' ')[0]
    },
    changeFilaAtend: async function({ added, removed }) {
      if (added) {
        await insertListaVezAPI.post({ ...added.element, situacao: 1 });
        this.socket.emit("SEND_RELOAD_FILA_DAVEZ", {});
      }
      if (removed) {
        await inativaListaVezAPI.post({ ...removed.element });
      }
    },
    changeAtend: async function({ added, removed }) {
      if (added) {
        await insertListaVezAPI.post({ ...added.element, situacao: 2 });
        this.socket.emit("SEND_RELOAD_FILA_DAVEZ", {});
      }
      if (removed) {
        await inativaListaVezAPI.post({ ...removed.element });
      }
    },
    changeOff: async function({ added, removed }) {
      if (added) {
        await insertListaVezAPI.post({ ...added.element, situacao: 0 });
        this.socket.emit("SEND_RELOAD_FILA_DAVEZ", {});
      }
      if (removed) {
        await inativaListaVezAPI.post({ ...removed.element });
      }
    },
    checkMoveAtend: function(evt) {
      return false;
    },
    getPhoto(foto) {
      const schema = (session.get('schema'));
      return foto ? `${process.env.VUE_APP_API_URL}get-photo/${schema}/${foto}` : './img/avatars/no-photo.jpg'
    },
    getAvatar(e) {
      e.target.src = './img/avatars/no-photo.jpg'
    },
    toggleAccordion() {
      const minimized = document.body.classList.contains('fila-atend-footer-minimized')
      const elem = document.getElementById('accordion-footer')
      const card = document.getElementsByClassName('card-deck')

      if (minimized) {
        elem.classList.add('show')
        elem.classList.remove('hide')

        document.body.classList.remove('fila-atend-footer-minimized')
        if (['md', 'lg'].includes(this.$mq)) {
          card[0].classList.remove('card-body-expanded')
          card[1].classList.remove('card-body-expanded')
        }
      } else {
        elem.classList.remove('show')
        elem.classList.add('hide')

        document.body.classList.add('fila-atend-footer-minimized')
        if (['md', 'lg'].includes(this.$mq)) {
          card[0].classList.add('card-body-expanded')
          card[1].classList.add('card-body-expanded')
        }
      }
    },
    scrollBottom(e) {
      const elem = document.getElementsByClassName('items')[e === 'FA' ? 0 : 1]
      easyScroll({
        'scrollableDomEle': elem,
        'direction': 'bottom',
        'duration': 300,
        'easingPreset': 'easeInQuad',
        'scrollAmount': 80,
        onAnimationCompleteCallback: () => {
          this.ajustArrows()
        }
      });
    },
    scrollTop(e) {
      const elem = document.getElementsByClassName('items')[e === 'FA' ? 0 : 1]
      easyScroll({
        'scrollableDomEle': elem,
        'direction': 'top',
        'duration': 300,
        'easingPreset': 'easeInQuad',
        'scrollAmount': 100,
        onAnimationCompleteCallback: () => {
          this.ajustArrows()
        }
      });
    },
    scrollLeft() {
      const users = this.usersOffline.length
      if (users === 0) {
        return false;
      }
      const elem = document.getElementById('offline-list')
      const marginLeft = elem.style.marginLeft.replace(/[^\d-]/g, '')
      if (Number(marginLeft) - 123 < -(123 * (users - 3))) {
        elem.style.marginLeft = `-${(123 * (users - 3))}px`
        if (elem.style.marginLeft === '0px') {
          elem.style.marginLeft = '-20px'
        }
      } else {
        elem.style.marginLeft = `${Number(marginLeft) - 123}px`
      }
      this.ajustArrows()
    },
    scrollRight() {
      if (this.usersOffline.length === 0) {
        return false;
      }
      const elem = document.getElementById('offline-list')
      let marginLeft = elem.style.marginLeft.replace(/[^\d-]/g, '')
      if (Number(marginLeft) >= -20) {
        elem.style.marginLeft = '-20px'
      } else {
        elem.style.marginLeft = `${Number(marginLeft) + 123}px`
      }
      if (elem.style.marginLeft === '0px') {
        elem.style.marginLeft = '-20px'
      }
      this.ajustArrows()
    },
    async refresh() {
      await initFuncAPI.get({loja: (session.get("loja"))});
      await this.reloadListaVez();
    },
    openPerformance() {
      this.showModalPerf();
    },
    selectedUserPerfor(user) {
      this.nome_user_perf = this.getNomeFuncionario(user);
      this.isUserPerformance = true;
      this.login = user.fd_login;
    },
    async loginPerformance() {
      const { login, password } = this;

      if (!login || !password) {
        return swal({
          title: 'Informação',
          text: 'Preencha os campos corretamente.',
          icon: 'info',
          dangerMode: true
        });
      }

      const {data} = await authPerfAPI.post({
        login, password,
        secret: sha256(process.env.VUE_APP_SECRET)
      });

      if (data.token) {
        return this.$router.push(
          {path: '/performance/'
         + window.btoa(data.adm_colaborador_id) 
         + '/' + window.btoa(data.loja) 
         + '/' + window.btoa(data.fd_arquivo_foto_minhas_vendas)
         + '/' + window.btoa(this.nome_user_perf)
         });
      }

      return swal({
        title: 'Informação',
        text: 'Login ou senha incorreto(s).',
        icon: 'warning',
        dangerMode: true
      });
    }
  },
  mounted() {
    this.socket.on("ON_RELOAD_FILA_DAVEZ", data => {
      if (data && data.evento === 'NOVA_VENDA') {
        this.$refs["modal"].hide();
      }
      this.refresh()
    });
  },

  async created() {
    await this.initFuncionarios();
    this.infor_loja = session.get('loja') + " - " + String(session.get('loja_desc').replace(/"/g, " "));
  }
};
</script>

<style>
html,
body,
.container-fluid {
  height: 100%;
}

.card-deck {
  height: 65vh;
}
.card-body-expanded {
  height: 81vh !important;
}
.card-deck .items {
  height: 56vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.fila-atend-footer-minimized .card-deck .items {
  height: 72vh;
}
#offline-list {
  height: inherit;
  width: 400%;
  margin-left: -20px;
}
.offline-items {
  height: inherit;
}

.scroll-right {
  width: 40px;
  height: 10em;
  position: absolute;
  left: 0
}
.scroll-left {
  width: 40px;
  height: 10em;
  position: absolute;
  right: 0
}

.animate {
  transition-delay: .1s;
  transition-duration: .10s;
  transition-timing-function: ease-in;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 20px;
}

.footer {
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 20px;
}

.ratio {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;
  border: none;
}
.img-circle {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img-responsive {
  display: block;
}

.list-group-item {
  cursor: move;
  border: none;
}
.user-detail {
  display: flex;
  align-items: left;
  justify-content: left;
  cursor: pointer;
  border: none;
}

.btn-primary {
  color: #ffffff;
}

.modal-body .card-header .btn-secondary {
  background-color: #ffffff;
  border-color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.checkAtendimento .custom-control-inline,
.checkAtendimento .form-check-inline {
  width: 140px;
  font-size: 21px;
}
.checkAtendimento .custom-control-inline .custom-control-label span {
  bottom: 6px;
  position: relative;
}

.footer-center {
  display: block !important;
  text-align: center !important;
  width: 100%;
}
.footer-center button {
  font-weight: bold;
  width: 140px;
  margin: 0px 7px;
}

.modal-body {
  max-height: 350px;
}
.select-user-perf {
  text-align: center;
  font-weight: bold;
}
.select-user-perf label {
  border: 2px solid #d8d8d8;
  height: 32px;
  padding: 3px;
  width: 229px;
}
.select-user-perf label:hover {
  background-color: #C8CED3;
  cursor: pointer;
}
</style>